import $jq from 'jquery'
// import axios from 'axios'
// const utils = window.utils
const wx = window.wx
export default {
  GetRequest () {
    var url = location.search
    // 获取url中'?'符后的字串
    var theRequest = {}
    if (url.indexOf('?') !== -1) {
      var str = url.substr(1)
      var strs = str.split('&')
      for (var i = 0; i < strs.length; i++) {
        theRequest[strs[i].split('=')[0]] = unescape(strs[i].split('=')[1])
      }
    }
    return theRequest
  },
  getUserCodeConfig (routerName, objectData) {
    if (!window.localStorage.getItem('userOption')) {
      var code = this.GetRequest().code // 获取url中的code信息
      var callBackUrl = encodeURIComponent(`https://xcx.gnxzhzx.com/#/${routerName}`) // 需要重定向的地址
      // var callBackUrl = encodeURIComponent(`http://lygtest.05oa.com/wx12345/index.html#/${routerName}`) // 需要重定向的地址
      // var callBackUrl = encodeURIComponent(`http://ssp.jyit.org/websites/wx12345/html/index.html#/${routerName}`) // 需要重定向的地址
      var appId = objectData.appId // 微信公众号appId
      var state = '' // 需要传参的参数
      console.log(callBackUrl, objectData)
      var redirectURI = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${callBackUrl}&response_type=code&scope=snsapi_userinfo&state=${state}#wechat_redirect` // 跳转微信的获取授权open网页
      if (!code) { // 这里如果没有code参数则重定向到微信获取用户openId的地址
        console.log(redirectURI)
        window.location.href = redirectURI
      } else {
        // var postUrl = `http://ssp.jyit.org/websites/wx12345/api/getId.jsp?code=${code}`
        var postUrl = `https://xcx.gnxzhzx.com:1690/getOpenId?code=${code}`
        $jq.get(postUrl, function (data) {
          console.log('外面的data: ', data)
          window.localStorage.setItem('userOption', JSON.stringify(data))
          window.location.reload()
        }, 'json')
      }
    } else {
      return JSON.parse(window.localStorage.getItem('userOption'))
    }
  },
  getAppUserCodeConfig () { // 用于app用户信息获取
    return window.userConfigOption
    // utils.callViewMethod('getUserInfo', 'getUser', {})
    // utils.registViewMethodCb('getUser', function (data) {
    //   console.log(data)
    //   if (utils.isAndroid()) {
    //     var evil = function (fn) { // eval eslint 报错
    //       var Fn = Function
    //       return new Fn('retrun' + fn)
    //     }
    //     data = JSON.stringify(data)
    //     var dataobj = data.substring(data.indexOf('{'), data.indexOf('}') + 1).replace(/=/g, ':').replace(/，/g, ',')
    //     console.log(dataobj)
    //     var obj2 = evil('(' + dataobj + ')')
    //     console.log(`完成初始化的: ${obj2}`)
    //     if (obj2.openid !== '') {
    //       console.log(obj2)
    //     } else {
    //       console.log(obj2)
    //       console.log('没有登录app')
    //     }
    //   }
    // })
  },
  getWxLocaltion (routerName) {
    console.log('getWxLocaltion()方法走了')
    var postData = {
      // url: 'http://lygtest.05oa.com',
      url: `https://xcx.gnxzhzx.com/#/${routerName}`,
      // code: this.GetRequest().code
      code: '021ju0000eTfGM1ul6400bbtGk3ju00C'
    }
    var postUrl = 'https://xcx.gnxzhzx.com/websites/wxConfig.jsp'
    // var postUrl = 'http://ssp.jyit.org/websites/wx12345/api/getWxConfig.jsp'
    // var postUrl = 'http://222.189.15.72:1188/websites/wxConfig.jsp'
    // axios.get(postUrl, postData).then(data => {
    $jq.post(postUrl, postData, function (data) {
      var signParam = data.data
      console.log(signParam)
      wx.config({
        debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: signParam.appId, // 必填，公众号的唯一标识
        timestamp: signParam.timestamp, // 必填，生成签名的时间戳
        nonceStr: signParam.nonceStr, // 必填，生成签名的随机串
        signature: signParam.signature, // 必填，签名，见官网附录1
        jsApiList: ['getLocation', 'scanQRCode']
      })
      wx.ready(function () {
        wx.checkJsApi({
          jsApiList: [
            'getLocation'
          ],
          success: function (res) {
            if (!res.checkResult.getLocation) {
              alert('你的微信版本太低，不支持微信JS接口，请升级到最新的微信版本！')
              return false
            }
          }
        })
        wx.error(function (res) {
          alert('接口调取失败')
        })
        wx.getLocation({
          success: function (res) {
            alert(JSON.stringify(res))
          },
          cancel: function (res) {
            alert('用户拒绝授权获取地理位置')
          }
        })
      })
    }, 'json')
    // wx.getLocation({
    //   type: 'wgs84',
    //   success: function (data) {
    //     console.log(data)
    //   }
    // })
    // var objectData = {
    //   appId: this.$store.getters.getInitData.appId,
    //   appSecret: this.$store.getters.getInitData.appSecret
    // }
    // var postUrl = `https://api.weixin.qq.com/cgi-bin/token?grant_type=client_credential&appid=${objectData.appId}&secret=${objectData.appSecret}`
    // $jq.get(postUrl, function (data) {
    //   console.log(data)
    //   var access_token = data.access_token
    // })
  }
}
