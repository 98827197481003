import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    appId: 'wxa652e851a223975b',
    appSecret: 'c95f7f92f0dbf434da8795245fc2e2cd',
    callBackUrlServer: '',
    openId: '',
    userPhone: '',
    userInfo: ''
  },
  getters: { // 暴露于外部获取state值的方法
    getInitData: function (state) {
      return state
    }
  },
  mutations: {
    updateOpenId (state, openId) { // 用于更新该文件的wxcode方法
      state.openId = openId
    },
    updateUserPhone (state, userPhone) {
      state.userPhone = userPhone
    },
    upuserInfo (state, userInfo) {
      state.userInfo = userInfo
    }
  },
  actions: {
    setOpenId: function ({ commit, state }, openId) { // 暴露于外部更新updateWxCode
      commit('updateOpenId', openId)
    },
    setUserPhone: function ({ commit, state }, userPhone) {
      commit('updateUserPhone', userPhone)
    },
    setUserInfo: function ({ commit, state }, userInfo) {
      commit('upuserInfo', userInfo)
    }
  },
  modules: {
  }
})
