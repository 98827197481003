<!-- 首页 -->
<template>
  <div class="homeCon">
    <div class="headDiv">
      <div class="headInfo">
        <div @touchstart="openConsole">说出您的诉求</div>
        <div>12345，欢迎您!</div>
      </div>
    </div>
    <!-- <div class="messageBox">
      <div class="hasMessage messageDiv" style="display: none;" @click="menuClick('sqcx')"></div>
    </div>
    <div class="headInfo">
      <div>说出您的诉求</div>
      <div>12345，欢迎您!</div>
    </div> -->
    <div class="homeConBody">
      <div class="indexDiv2">
          <mu-paper class="card animated zoomIn" round :z-depth="4">
            <div class="item" @click="menuClick('zxjj')">
              <div class="imgDiv">
                <img src="~@/assets/img/019.png" alt="" srcset="">
              </div>
              <div class="textDiv">
                中心简介
              </div>
            </div>
            <div class="item" @click="menuClick('zxzx')">
              <div class="imgDiv">
                <img src="~@/assets/img/020.png" alt="" srcset="">
              </div>
              <div class="textDiv">
                最新资讯
              </div>
            </div>
            <div class="item" @click="menuClick('rdjj')">
              <div class="imgDiv">
                <img src="~@/assets/img/021.png" alt="" srcset="">
              </div>
              <div class="textDiv">
                热点聚焦
              </div>
            </div>
            <div class="item" @click="menuClick('bmzs')">
              <div class="imgDiv">
                <img src="~@/assets/img/022.png" alt="" srcset="">
              </div>
              <div class="textDiv">
                便民知识
              </div>
            </div>
          </mu-paper>
      </div>
      <div class="indexDiv3 animated zoomIn indexDiv">
        <div class="title">
          <div class="titleName">诉求中心</div>
        </div>
        <div class="body">
          <div class="item wysq" @click="menuClick('wysq')"></div>
          <div class="item sqcx" @click="menuClick('sqcx')"></div>
        </div>
      </div>
      <div class="indexDiv4 animated zoomIn indexDiv">
        <div class="title">
          <div class="titleName">最新公告</div>
          <div class="moreDiv" @click="menuClick('tzgg')">更多</div>
        </div>
        <div class="body">
          <div class="item animated fadeInLeft" v-for="(e, i) in zxggReturnDataArr" :key="i" @click="cardClick(e.id)">
            <div class="textDiv">
              <div class="text">
                {{e.bt}}
              </div>
              <div class="timeDiv">{{e.time}}</div>
            </div>
            <div class="imgDiv" style="display: none;">
                <img src="~@/assets/img/026.png" alt="" srcset="">
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      consoleCount: 0,
      zxggReturnData: '',
      zxggReturnDataArr: [],
      zxggCurrPage: 0 // 当前页
    }
  },
  components: {},
  computed: {},
  created () {
    this.initUserOpenId()
  },
  methods: {
    openConsole () {
      this.consoleCount++
      if (this.consoleCount === 10) {
        var Vconsole = this.$Vconsole
        var console = new Vconsole()
        this.console = console
      }
    },
    initUserOpenId () {
      var vm = this
      var userConfig = this.$ownerUtil.getAppUserCodeConfig()
      console.log(userConfig)
      if (userConfig.openid === '') {
        window.utils.callViewMethod('login', 'Login', {})
      } else {
        var postData = {
          oppenId: userConfig.openid
        }
        vm.$axios.post('/realNameCheck', postData).then(res => {
          if (!res.data.data.isSuccess) {
            vm.$router.replace({
              path: '/xxgrxx'
            })
          }
        })
      }
    },
    zxgg () {
      var vm = this
      var postData = {
        currPage: vm.zxggCurrPage, // 当前页码 // 0为第一页
        pageSize: 5 // 一页多少条
      }
      vm.$axios.post('/announcements', postData).then(successData => {
        vm.zxggReturnData = successData.data.data[0]
        console.log(vm.zxggReturnData)
        vm.zxggReturnData.data.forEach(e => {
          // e.mx = decodeURI(window.atob(e.mx)) // mx 后台传过来是base64
          vm.zxggReturnDataArr.push(e)
        })
      })
    },
    menuClick (name) {
      setTimeout(() => {
        name === 'zxjj' && this.$router.push('/zxjj')
        name === 'zxzx' && this.$router.push('/zxzx')
        name === 'rdjj' && this.$router.push('/rdjj')
        name === 'bmzs' && this.$router.push('/bmzs')
        name === 'wysq' && this.$router.push('/wysq')
        name === 'tzgg' && this.$router.push('/tzgg')
        name === 'sqcx' && this.$router.push('/sqcx')
      }, 400)
    },
    cardClick (id) {
      setTimeout(() => {
        this.$parent.openFullscreenDialog('公告详情', `index.html#/infoDetails?id=${id}&type=tzgg`)
        // this.$router.push({
        //   path: '/zxzxDetails',
        //   query: {
        //     id: id
        //   }
        // })
      }, 400)
    }
  },
  mounted () {
    this.zxgg()
    this.$parent.firstIndex = this.$route.path
  }
}
</script>
<style lang="scss" scoped>
@import '../assets/css/home.scss';
</style>
