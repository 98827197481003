import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    meta: {
      title: '首页'
    },
    redirect: '/index'
  },
  {
    path: '/about',
    name: 'About',
    meta: {
      title: '关于'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }, {
    path: '/index',
    name: 'index',
    meta: {
      title: '首页'
    },
    redirect: '/home',
    component: () => import('../views/index.vue'),
    children: [
      {
        path: '/home',
        name: 'home',
        meta: {
          title: '首页'
        },
        component: Home
      },
      {
        path: '/tzgg',
        name: 'Tzgg',
        meta: {
          title: '通知公告'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/tzgg.vue')
      },
      {
        path: '/zxzxDetails',
        name: 'ZxzxDetails',
        meta: {
          title: '最新资讯详情'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/zxzxDetails.vue')
      },
      {
        path: '/infoDetails',
        name: 'infoDetails',
        meta: {
          title: '通用资讯详情'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/infoDetails.vue')
      },
      {
        path: '/wysq_new',
        name: 'Wysq',
        meta: {
          title: '我要诉求'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/wysq.vue')
      },
      {
        path: '/grzx',
        name: 'Grzx',
        meta: {
          title: '个人中心'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/grzx.vue')
      }, {
        path: '/zxkf',
        name: 'Zxkf',
        meta: {
          title: '智能客服'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/zxkf.vue')
      }, {
        path: '/wdqzLieBiao',
        name: 'WdqzLieBiao',
        meta: {
          title: '我的求助'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/wdqzLieBiao.vue')
      }, {
        path: '/zxjj',
        name: 'Zxjj',
        meta: {
          title: '中心简介'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/zxjj.vue')
      }, {
        path: '/rdjj',
        name: 'Rdjj',
        meta: {
          title: '热点聚焦'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/rdjj.vue')
      }, {
        path: '/bmzs',
        name: 'Bmzs',
        meta: {
          title: '便民知识'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/bmzs.vue')
      }, {
        path: '/zxzx',
        name: 'Zxzx',
        meta: {
          title: '最新资讯'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/zxzx.vue')
      }, {
        path: '/sqcx',
        name: 'sqcx',
        meta: {
          title: '我的诉求'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/wdsq.vue')
      }
    ]
  }, {
    path: '/bdgrxx',
    name: 'Bdgrxx',
    meta: {
      title: '绑定个人信息'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/bdgrxx.vue')
  },
  {
    path: '/xxgrxx',
    name: 'Xxgrxx',
    meta: {
      title: '输入详细个人信息'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/xxgrxx.vue')
  },
  {
    path: '/sqxq',
    name: 'Sqxq',
    meta: {
      title: '诉求详情'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/sqxq.vue')
  },
  {
    path: '/tel',
    name: 'tel',
    meta: {
      title: '一键拨号'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/tel.vue')
  },
  {
    path: '/ssp',
    name: 'ssp',
    meta: {
      title: '随手拍'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ssp.vue')
  },
  {
    path: '/getWeixinPoi',
    name: 'getWeixinPoi',
    meta: {
      title: '获取微信定位'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/getWeixinPoi.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
