import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import MuseUI from 'muse-ui'
import 'muse-ui/dist/muse-ui.css'
import Helpers from 'muse-ui/lib/Helpers'
import $jq from 'jquery'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import ownerUtil from '@/components/utils/CommonUtil.js'
import axios from 'axios'
import Toast from 'muse-ui-toast'
import 'muse-ui-message/dist/muse-ui-message.css'
import Message from 'muse-ui-message'
import 'muse-ui-progress/dist/muse-ui-progress.css'
import NProgress from 'muse-ui-progress'
import 'muse-ui-loading/dist/muse-ui-loading.css'
import Loading from 'muse-ui-loading'
import Vconsole from 'vconsole/dist/vconsole.min.js' // import vconsole
Vue.prototype.$Vconsole = Vconsole
// Vue.prototype.$Vconsole = new Vconsole()
Vue.use(Loading)
Vue.use(NProgress, {
  color: 'red'
})
Vue.use(Message)
Message.config({
  width: 350,
  className: 'messageBoxDiv'
})
Vue.use(Toast)
Vue.use(Viewer, {
  defaultOptions: {
    zIndex: 9999
  }
})
// axios.defaults.baseURL = '../weChat'
axios.defaults.baseURL = 'https://xcx.gnxzhzx.com:1690/weChat'
// axios.defaults.baseURL = 'http://222.189.15.72:1688/weChat' // 正式服务器
// axios.defaults.baseURL = 'http://222.189.15.72:1888/weChat' // 测试服务器
var _ = require('lodash')
Vue.prototype.$jq = $jq
Vue.prototype._ = _
Vue.prototype.$axios = axios

Vue.use(ElementUI)
Vue.use(MuseUI)
Vue.use(Helpers)
Vue.prototype.$ownerUtil = ownerUtil
// MuseUI.theme.use('dark')
// if (new Date().getHours() > 16 || new Date().getHours() < 6) MuseUI.theme.use('dark') // 使用黑色主题
Vue.config.productionTip = false
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
