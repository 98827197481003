<template>
  <!-- <div id="app" :style="{color: fontColor}"> -->
  <div id="app">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <router-view/>
  </div>
</template>

<script>
export default {
  data () {
    return {
      // fontColor: new Date().getHours() > 16 || new Date().getHours() < 6 ? 'white' : 'black'
    }
  }
}
</script>

<style>

</style>script
<style lang="scss">
@import './assets/css/animate.css';
html, body {
  margin: 0px;
  padding: 0;
  width: 100%;
  height: 100%;
  font-size: 62.5%;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  // color: #2c3e50;
  width: 100%;
  height: 100%;
  font-size: 1.6rem;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
